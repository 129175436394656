.TicketCard {
    position: relative;
    display: inline-block;
    width: calc(25% - 10px);
    max-width: 300px;
    min-height: 200px;
    border: 3px solid #fcb883;
    border-radius: 8px;
    padding: 5px;
    margin: 20px 5px 0px 5px;
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    .ticket-date {
        font-weight: bold;
    }
    .ticket-icon {
        position: absolute;
        top: -27px;
        left: calc(50% - 28px);
        background: #fbe6d4;
        font-size: 36px;
        padding: 0px 10px;
        border-radius: 50%;
    }
    .ticket-user {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }
    .ticket-zone {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }
    .ticket-progress-list {
        height: 90px;
        overflow-y: auto;
        .ticket-progress {
            .progress-time {
                display: inline-block;
                vertical-align: middle;
                width: 40%;
                text-align: left;
                font-size: 12px;
            }
            .progress-message {
                display: inline-block;
                vertical-align: middle;
                width: 60%;
                text-align: left;
                font-size: 12px;
            }
            &:first-of-type {
                font-weight: bold;
            }
        }
    }
    .ticket-btn {
        text-align: center;
    }
}