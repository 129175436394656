.TicketCardModal {
    .ant-modal-body {
        padding-top: 60px;
        padding-bottom: 30px;
        .TicketCard {
            display: block;
            width: 60%;
            margin: auto;
            .ticket-progress-list {
                height: 120px;
            }
        }
        .form-section {
            margin-top: 15px;
            .sub-title {
                text-align: center;
                font-size: 16px;
                margin-bottom: 0;
                font-weight: bold;
            }
            .footer {
                text-align: center;
            }
        }
    }
}